<template>
    <div class="wechat_share_container">
        <article class="article-details">
            <div class="isDelete" v-if="isDelete">感谢您的关注，该文章已经删除</div>
            <div class="notDelete" v-if="!isDelete">
                <div class="artcile-base-info">
                    <div class="article_title">{{ article.title }}</div>
                    <div class="creater_company">
                        <span>{{ article.userCard.merchantName }}</span>
                        <span>{{ article.createTime }}</span>
                    </div>
                </div>
                <van-sticky>
                    <van-skeleton title avatar :row="3" v-if="showSkeleton"></van-skeleton>
                    <div v-else class="card-box-cls">
                        <div class="card-box">
                            <div class="card-info" @click="handleViewUserCard">
                                <div class="card-left">
                                    <img :src="cardImg ? cardImg : morenHeadImg" alt=""/>
                                    <div class="creater-info">
                                        <div class="title">{{ article.userCard.name }}</div>
                                        <div class="second" v-if="article.userCard.impressionLabel">
                                            {{ article.userCard.impressionLabel }}
                                        </div>
                                    </div>
                                </div>
                                <div class="card-right" @click.stop="handleCall">
                                    <div class="phone">电话联系</div>
                                </div>
                            </div>
                            <div class="synopsis">
                                <span>{{ isFold ? foldSynopsisStr : synopsisStr }}</span>
                                <span
                                        style="color: #999"
                                        @click="handleIsFold"
                                        v-if="synopsisStr.length > 40"
                                >{{ isFold ? "...展开" : "&lt;&lt;折叠" }}</span
                                >
                            </div>
                        </div>
                    </div>
                </van-sticky>
                <!-- <div class="cover-img">
                          <div class="img-box">
                            <img :src="article.image" alt="" />
                          </div>
                        </div> -->
                <div class="artcle-content">
                    <div class="artcle-content-box">
                        <transform-html :contents="article.content"></transform-html>
                    </div>
                </div>
                <div class="article_statement">
                    声明：该文章来源互联网，由{{ article.userCard.name }}(ID:{{
                    article.userCard.cardId
                    }})整理发布或转载，如有侵权请及时联系平台客服进行删除，版权归原作者所有，本处仅作为分享使用。
                </div>
                <!-- <div class="leave-message-time">
                              {{ it.createTime.slice(0, 10) }}
                            </div> -->
                <!-- 收藏点赞 -->
                <div class="star-leaving-box">
                    <div class="star-collection">
                        <div class="star" @click="handleStarOrCollect('star')">
                            <div>
                                <icon-svg
                                        :iconClass="starShow ? 'iconGroup91' : 'iconthumbs-up'"
                                        class=""
                                />
                            </div>
                            {{ article.countOfLikes }}
                        </div>
                        <div class="collection" @click="handleStarOrCollect('collect')">
                            <div>
                                <icon-svg
                                        :iconClass="collectShow ? 'iconstar1' : 'iconstar'"
                                        class=""
                                />
                            </div>
                            {{ article.countOfCollection }}
                        </div>
                    </div>
                    <div class="leaving-botton-ts">
                        <button
                                :class="leavMsg ? 'button-bc' : ''"
                                @click="handleOpenLeavingMsg"
                        >
                            留言
                        </button>
                        <span @click="handletoushu">投诉</span>
                    </div>
                </div>

                <!-- 留言 -->
                <div class="leave-message-box" v-for="it in msgList" :key="it.id">
                    <div class="user-info">
                        <div class="img-content">
                                <img :src="it.headImgUrl ? `${it.headImgUrl}` : morenHeadImg" alt=""/>
                                <div class="name-content">
                                  <div class="name">{{ it.wetchatUsername }}</div>
                                  <div class="content">{{ it.content }}</div>
                                </div>
                        </div>

                    </div>
                  <div class="leave-message-time">
                  <div style="width: 40px;">
                    <div v-if="it.hasSubReply==1" style="width: 40px;display: flex;align-items: center">
                      <van-icon name="arrow-up" class="icon-cls" @click="getLeavingMsgAllReply(it.id)" v-show="it.show"/>
                      <van-icon name="arrow-down" class="icon-cls"  @click="closeReply(it.id)" v-show="!it.show"/>
                    </div>
                  </div>
                    <span class="time"> {{ it.createTime}}</span><span class="reply" @click="handleReplyInfo(it)">回复</span>
                  </div>

                  <!--回复-->
                  <div class="leave-message-box margin-cls" v-for="item in it.reply" :key="item.id">
                    <div class="user-info">
                      <div class="img-content">
                        <img :src="item.headImgUrl ? `${item.headImgUrl}` : morenHeadImg" alt=""/>
                        <div class="name-content">
                          <div class="name">{{ item.wetchatUsername }}</div>
                          <div class="content">{{ "回复@"+item.parentWechatUserName +"："+item.content}}</div>
                        </div>
                      </div>

                    </div>
                    <div class="leave-message-time-child">
                      <span class="time"> {{ item.createDate }}</span><span class="reply" @click="handleReplyInfo(it,item)">回复</span>
                    </div>
                  </div>

                </div>
              <!--                  加载更多-->
              <div style="background-color: #FFFFFF;padding: 10px 0;" v-if="showMoreButton">
                <span v-if="showMore" style="font-size: 14px;color: #289fff" @click="loadMore">加载更多评论</span>
                <van-loading v-else color="#1989fa" >加载中...</van-loading>
              </div>
              <div style="background-color: #FFFFFF;padding: 10px 0;" v-else>
                <span v-if="showMore" style="font-size: 14px;color: #cccccc">没有更多了</span>
              </div>
                <!-- 创建留言 -->
                <div class="create-leave-message" v-if="leavMsg">
                    <div class="name-card">
                        <div class="name-info">
                            <img :src="userInfo.headImgUrl" alt=""/>
                            <div class="creater-info">
                                <p>{{ userInfo.nickname }}</p>
                            </div>
                        </div>
                        <div class="synopsis">
                            <van-field
                                    v-model="leaveMsgParams.content"
                                    rows="3"
                                    autosize
                                    type="textarea"
                                    :placeholder="writeLeaveMsg"
                                    show-word-limit
                            />
                            <div class="cancel-save">
                                <van-button
                                        square
                                        type="primary"
                                        color="#FF6E01"
                                        @click="saveLeaveMsg"
                                        v-if="leaveMsgParams.content"
                                >发送
                                </van-button>
                                <span @click="saveLeaveMsg('cancel')">取消</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 热门 -->
            <div class="hot-article">
                <p :class="!isDelete ? 'hot-title' : ''">热门文章</p>
                <div class="all-hot-article">
                    <div
                            class="hot-article-box"
                            v-for="it in hotArticles"
                            :key="it.articleId"
                            @click="loadOtherHotArticle(it.articleId)"
                    >
                        <img
                                :src="it.headImgUrl ? `${imgUrl}/${it.headImgUrl}` : morenHeadImg"
                                alt=""
                        />
                        <span>{{ it.title }}</span>
                    </div>
                    <div class="changeOther" @click="changeOther">换一批</div>
                </div>
            </div>
        </article>

        <footer class="wechat_footer">
            <van-tabbar v-model="active" @click="handleChoice" v-if="!isDelete">
                <van-tabbar-item>
                    <icon-svg iconClass="iconsmartphone1" class="zixun"/>
                    <span>电话联系</span>
                </van-tabbar-item>
                <van-tabbar-item>
                    <icon-svg iconClass="iconmessage-square" class="zixun"/>
                    <span>我要咨询</span>
                </van-tabbar-item>
            </van-tabbar>
        </footer>
        <!-- 咨询 -->
        <div class="consulting">
            <van-overlay :show="showConsulting" @click="showConsulting = false">
                <div class="wrapper" @click.stop>
                    <div class="block">
                        <p class="title">我要咨询</p>
                        <van-form ref="addInfoForm" error-message-align="right">
                            <div class="info">
                                <van-field
                                        v-model="addInfo.username"
                                        label="姓名"
                                        :rules="[{ required: true, message: '请输入名称' }]"
                                />
                                <van-field
                                        v-model="addInfo.phone"
                                        label="手机号"
                                        type="tel"
                                        :rules="[
                    {
                      required: true,
                      pattern: /^1[3|4|5|7|8]\d{9}$/,
                      message: '请输入格式正确的手机号',
                    },
                  ]"
                                />
                                <van-field
                                        v-model="addInfo.content"
                                        label="留言"
                                        type="textarea"
                                        :rules="[{ required: true, message: '请输入留言' }]"
                                />
                            </div>
                        </van-form>
                        <van-grid clickable :column-num="2">
                            <van-grid-item text="返回" @click="handlecombaack"/>
                            <van-grid-item text="保存" @click="handleAddInfo"/>
                        </van-grid>
                    </div>
                </div>
            </van-overlay>
        </div>
    </div>
</template>

<script>
    import {computed, onMounted, onUnmounted, reactive, ref, toRefs, watch,} from "vue";
    import {useRoute, useRouter} from "vue-router";
    // import { getArticleList } from "../../api/articleManagement";
    import {findHotArticle, getArticleById,findArticleLeavingMsgByPage,findLeavingMsgAllReply} from "../../api/weChat";
    import transformHtml from "../../components/transformHTML.vue";
    import {searchFileList} from "../../api/file";
    import {useWxAuthorization} from "../../hooks/wxAuthorization";
    import notfound from "@/assets/head-img-not-found.png";
    import {Dialog, Toast} from "vant";
    import {addNum, createArticleLeavingMsg, reduceNum,} from "../../api/huokeArticle";
    import {createConsult} from "@/api/user/userCard";
    import $ from "jquery";
    import {initWechatShare} from "../../hooks/initWechatShare";
    import {addClue, addClueBehaviorInfo} from "../../api/user/clue";

    export default {
        components: {
            transformHtml,
        },
        setup() {
            const route = useRoute();
            const router = useRouter();
            let internal = null;
            let seconds = 0;
            let addInfoForm = ref(null);
            const merchantId = route.params.merchantId;
            const recommendClueId = route.params.recommendClueId ? route.params.recommendClueId : ''
            const state = reactive({
                writeLeaveMsg:'写下你的留言/回复',
                childParentId:'',
                isChild:false,
                parentId:'',
                showToTop:true,
                showMoreButton:false,
                showMore:true,
                current:1,
                showSkeleton: true,
                starShow: false,
                collectShow: false,
                leavMsg: false,
                leavingMessage: "",
                consultingName: "",
                consultingPhone: "",
                consultingText: "",
                articleId: route.params.id,
                openId: "",
                consultId: null,
                active: null,
                showConsulting: false,
                isDelete: 0,
                hotArticles: {},
                article: {
                    title: "",
                    createTime: "",
                    content: "",
                    countOfLikes: "",
                    countOfCollection: "",
                    image: "",
                    createMerchantId: "",
                    msgList: [],
                    userCard: {},
                    isDelete: null,
                    loginUserId: "",
                    smallCoverPic: "",
                    isLike: null,
                    isCollection: null,
                },
                fileParams: {
                    busiId: route.params.id,
                    busiCat: "article",
                    groupId: "articleIndexPic",
                },
                userInfo: {},
                imgUrl: process.env.VUE_APP_WATCH_FILE_API,
                morenImg: "",
                morenHeadImg: require("../../assets/icons/Vector.png"),
                msgList: [],
                leaveMsgParams: {
                    content: "",
                },
                addInfo: {
                    username: "",
                    phone: "",
                    content: "",
                    busiId: route.params.id,
                },

                synopsisStr: "",
                foldSynopsisStr: "",
                isFold: true,
                clueId: "", //线索ID
            });
            watch(
                () => route.params.id,
                (newVal) => {
                    if (newVal) {
                        let temp = location.href.split("?")[0];
                        if (recommendClueId) {
                            temp = temp.replace(recommendClueId, state.clueId)
                        } else {
                            temp = `${temp}/${state.clueId}`
                        }
                        getArticleById(newVal, state.openId).then((data) => {
                            state.article.title = data.title;
                            state.article.createTime = data.createTime
                                ? data.createTime.slice(0, 10)
                                : "";
                            state.article.content = data.content;
                            state.article.countOfLikes = data.countOfLikes;
                            state.article.countOfCollection = data.countOfCollection;
                            state.article.createMerchantId = data.createMerchantId;
                            state.article.userCard = data.userCard;
                            state.article.isDelete = data.isDeleted;
                            state.article.loginUserId = data.loginUserId;
                            state.isDelete = data.isDeleted;
                            // state.msgList = data.msgList;
                            state.articleId = data.articleId;
                            // console.log("是否删除", state.isDelete);
                            state.article.smallCoverPic = data.smallCoverPic;
                            state.synopsisStr = data.userCard.selfIntro
                                ? data.userCard.selfIntro
                                : "我坚信细节决定品质，专业决定价值！如有需求，请随时与我联系！";
                            state.foldSynopsisStr = state.synopsisStr.substring(0, 44);
                            state.isLike = data.isLike;
                            if (state.isLike === 0) {
                                state.starShow = true;
                            } else {
                                state.starShow = false;
                            }
                            state.isCollection = data.isCollection;
                            if (state.isCollection === 0) {
                                state.collectShow = true;
                            } else {
                                state.collectShow = false;
                            }
                            state.showSkeleton = false
                            addNum(state.articleId, "views", state.openId);
                            console.log("微信分享", data);
                            let shareImg;
                            if (state.article.smallCoverPic) {
                                shareImg = `${process.env.VUE_APP_WATCH_FILE_API}/${state.article.smallCoverPic}`;
                            } else {
                                shareImg = "https://wca.ujiaku.com/upload/wx/share-default.png";
                            }
                            // eslint-disable-next-line no-undef
                            wx.miniProgram.getEnv(function (res) {
                                if (res.miniprogram) {
                                    // eslint-disable-next-line no-undef
                                    wx.miniProgram.postMessage({
                                        data: {
                                            title: state.article.title,
                                            img: shareImg,
                                            openId: state.openId,
                                            clueId: state.clueId,
                                            type: 4,
                                            shareUrl: temp,
                                        },
                                    });
                                } else {
                                    let title = state.article.title;
                                    let desc = state.article.title;
                                    let url = `${
                                        process.env.VUE_APP_WECHAT_SHARE
                                    }/shareForward?shareUrl=${temp}?appPhone=${localStorage.getItem(
                                        "appPhone"
                                    )}`;
                                    let imgUrl = shareImg;
                                    initWechatShare({title, desc, url, imgUrl});
                                }
                            });

                        });
                        window.scroll(0, 0);
                        state.current = 1
                        getArticleLeavingMsgByPage()
                    }
                }
            );
            const loadMore = ()=>{
              state.showMore = false
              state.current = state.current + 1
              getArticleLeavingMsgByPage()
            }
            const handletoushu = () => {
                Dialog.confirm({
                    title: "投诉",
                    message: "确定要投诉该内容吗",
                    cancelButtonColor: "#999999",
                    confirmButtonColor: "#000000",
                    className: "toushu",
                }).then(() => {
                    // countUserAction({clueId:state.clueId,openId:state.openId,type:6})
                });
            };
            const loadOtherHotArticle = (id) => {
                if (id) {
                    let miniProgramPhone = route.query.state
                        ? route.query.state
                        : route.query.miniProgramPhone;
                    router.push({
                        path: `/weChatShare/${id}/${route.params.clueShareSource}/${merchantId}/${recommendClueId}`,
                        query: {miniProgramPhone: miniProgramPhone, openId: state.openId},
                    });
                }
            };
            //点赞或者收藏
            const handleStarOrCollect = (Str) => {
                if (Str === "star") {
                    if (state.starShow === false) {
                        state.starShow = true;
                        state.article.countOfLikes++;
                        addNum(state.articleId, "likes", state.openId);
                        countUserAction({
                            clueId: state.clueId,
                            openId: state.openId,
                            type: 6,
                        });
                    } else {
                        state.starShow = false;
                        state.article.countOfLikes--;
                        reduceNum(state.articleId, "likes", state.openId);
                    }
                } else if (Str === "collect") {
                    if (state.collectShow === false) {
                        state.collectShow = true;
                        state.article.countOfCollection++;
                        addNum(state.articleId, "collection", state.openId);
                        countUserAction({
                            clueId: state.clueId,
                            openId: state.openId,
                            type: 7,
                        });
                    } else {
                        state.collectShow = false;
                        state.article.countOfCollection--;
                        reduceNum(state.articleId, "collection", state.openId);
                    }
                }
            };

            //打开留言
            const handleOpenLeavingMsg = () => {
                state.leavMsg = !state.leavMsg;
                state.writeLeaveMsg = '发表您的留言'
                console.log("留言按钮");
            };
            // useWxAuthorization().then((data) => {
            //   if (data) {
            //     state.userInfo = data;
            //     state.openId = data.openId;
            //   } else {
            //     state.openId = route.query.openId;
            //   }
            // });

            const useWxAuthorOpenId = async () => {
                let data = await useWxAuthorization();
                if (data) {
                    state.userInfo = data;
                    state.openId = data.openId;
                } else {
                    state.openId = route.query.openId;
                }
              // await loadArticleDetails();
              // await loadArticleDetails();
              // getArticleLeavingMsgByPage()

                if (state.openId) {
                    addNum(state.articleId, "views", state.openId);
                    await loadArticleDetails();
                    getArticleLeavingMsgByPage()
                    await handleFirstAddClue();
                    let temp = location.href.split("?")[0];
                    if (recommendClueId) {
                        temp = temp.replace(recommendClueId, state.clueId)
                    } else {
                        temp = `${temp}/${state.clueId}`
                    }
                    //用户查看行为记录
                    countUserAction({
                        clueId: state.clueId,
                        openId: state.openId,
                        type: 1,
                    });
                    let shareImg;
                    if (state.article.smallCoverPic) {
                        shareImg = `${process.env.VUE_APP_WATCH_FILE_API}/${state.article.smallCoverPic}`;
                    } else {
                        shareImg = "https://wca.ujiaku.com/upload/wx/share-default.png";
                    }
                    // eslint-disable-next-line no-undef
                    wx.miniProgram.postMessage({
                        data: {
                            title: state.article.title,
                            img: shareImg,
                            openId: state.openId,
                            clueId: state.clueId,
                            shareUrl: temp,
                            type: 4,
                        },
                    });
                    // eslint-disable-next-line no-undef
                    wx.miniProgram.getEnv(function (res) {
                        if (res.miniprogram) {
                            state.showSavePhone = true;
                        } else {
                            let title = state.article.title;
                            let desc = state.article.title;
                            let appPhone
                            if (localStorage.getItem("appPhone")) {
                                appPhone = localStorage.getItem("appPhone")
                            } else {
                                appPhone = route.query.appPhone
                            }
                            let url = `${process.env.VUE_APP_WECHAT_SHARE}/shareForward?shareUrl=${temp}?appPhone=${appPhone}`
                            let imgUrl = shareImg;
                            initWechatShare({
                                title,
                                desc,
                                url,
                                imgUrl,
                                openId: state.openId,
                                clueId: state.clueId,
                                type: 4,
                            });
                        }
                    });
                }
            };
            useWxAuthorOpenId();
            //热门文章
            const loadHotArticle = async () => {
                let result = await findHotArticle(3);
                state.hotArticles = result;
                console.log("热门", state.hotArticles);
            };
            loadHotArticle();
            const changeOther = () => {
                loadHotArticle();
            };
            let cardImg = computed(() => {
                if (state.article.userCard.headImgUrl) {
                    return `${process.env.VUE_APP_WATCH_FILE_API}/${state.article.userCard.headImgUrl}`;
                } else {
                    return notfound;
                }
            });
            const handleChoice = () => {
                if (state.active === 1) {
                    state.showConsulting = true;
                } else if (state.active == 0) {
                    if (state.article.userCard.contactNum) {
                        let a = document.createElement("a");
                        a.setAttribute("href", `tel:${state.article.userCard.contactNum}`);
                        a.click();
                        //记录用户拨打电话
                        countUserAction({
                            clueId: state.clueId,
                            openId: state.openId,
                            type: 2,
                        });
                    } else {
                        Dialog.alert({title: "错误", message: "没有电话号码"});
                    }
                }
            };
            const handleCall = () => {
                if (state.article.userCard.contactNum) {
                    let a = document.createElement("a");
                    a.setAttribute("href", `tel:${state.article.userCard.contactNum}`);
                    a.click();
                    //记录用户拨打电话
                    countUserAction({
                        clueId: state.clueId,
                        openId: state.openId,
                        type: 2,
                    });
                } else {
                    Dialog.alert({title: "错误", message: "没有电话号码"});
                }
            };
            const handleAddInfo = () => {
                let param = {
                    ...state.addInfo,
                };
                addInfoForm.value.validate().then(() => {
                    createConsult(param).then((data) => {
                        if (data) {
                            state.consultId = data.id;
                            // Dialog.alert({ title: "成功", message: "您的咨询已创建" });
                            Toast.success("您的咨询已创建");
                            state.showConsulting = false;
                            countUserAction({
                                clueId: state.clueId,
                                openId: state.openId,
                                type: 5,
                            });
                        }
                    });
                });
            };
            const handlecombaack = () => {
                state.showConsulting = false;
            };
            const handleViewUserCard = () => {
                let miniProgramPhone = route.query.state
                    ? route.query.state
                    : route.query.miniProgramPhone;
                router.push({
                    path: `/wxShareUserCard/${route.params.clueShareSource}/${merchantId}/${recommendClueId}`,
                    query: {miniProgramPhone: miniProgramPhone, openId: state.openId},
                });
            };
            const countUserAction = async ({clueId, openId, type}) => {
                /**
                 * type:
                 *  1查看
                 *  2拨打
                 *  3保存
                 *  4转发
                 *  5提交咨询
                 *  6点赞
                 *  7收藏
                 *  8留言
                 */

                if (clueId) {
                    await addClueBehaviorInfo({clueId, openId, type});
                }
            };
            const handleWindowHide = () => {
                if (seconds > 0) {
                    let miniProgramPhone = route.query.state
                        ? route.query.state
                        : route.query.miniProgramPhone;
                    let param = {
                        browseSecond: seconds,
                        clueId: state.clueId,
                        shareId: state.articleId,
                        clueSource: 1,
                        beSharePhone: miniProgramPhone,
                        openId: state.openId,
                        title: state.article.title,
                        createId: state.article.loginUserId,
                        merchantId,
                        consultId: state.consultId,
                        clueShareSource: route.params.clueShareSource,
                        recommend_Clue_Id: recommendClueId,
                    };
                    let blob = new Blob([JSON.stringify(param)], {
                        type: "application/json",
                    });
                    navigator.sendBeacon(
                        `${process.env.VUE_APP_BASE_API}/user/clue/addClue`,
                        blob
                    );
                    seconds = 0;
                    clearInterval(internal);
                }
            };
            const handleFirstAddClue = async () => {
                let miniProgramPhone = route.query.state
                    ? route.query.state
                    : route.query.miniProgramPhone;
                let param = {
                    shareId: state.articleId,
                    clueSource: 1,
                    browseSecond: 0,
                    beSharePhone: miniProgramPhone,
                    openId: state.openId,
                    title: state.article.title,
                    createId: state.article.loginUserId,
                    consultId: state.consultId,
                    clueShareSource: route.params.clueShareSource,
                    recommend_Clue_Id: recommendClueId,
                    merchantId
                };
                let data = await addClue(param);
                state.clueId = data;
                return data;
                // $.ajax({
                //   async: false,
                //   url: `${process.env.VUE_APP_BASE_API}/user/clue/addClue`,
                //   type: "post",
                //   contentType: "application/json;charset=UTF-8",
                //   data: JSON.stringify(param),
                //   success: (data) => {
                //     if (data) {
                //       state.clueId = data;
                //       return data
                //     }
                //   },
                // });
            };
            const handleAddClue = () => {
                if (seconds > 0) {
                    let miniProgramPhone = route.query.state
                        ? route.query.state
                        : route.query.miniProgramPhone;
                    let param = {
                        browseSecond: seconds,
                        clueId: state.clueId,
                        shareId: state.articleId,
                        clueSource: 1,
                        beSharePhone: miniProgramPhone,
                        openId: state.openId,
                        title: state.article.title,
                        createId: state.article.loginUserId,
                        merchantId,
                        consultId: state.consultId,
                        clueShareSource: route.params.clueShareSource,
                        recommend_Clue_Id: recommendClueId,
                    };
                    $.ajax({
                        async: false,
                        url: `${process.env.VUE_APP_BASE_API}/user/clue/addClue`,
                        type: "post",
                        contentType: "application/json;charset=UTF-8",
                        data: JSON.stringify(param),
                    });
                    seconds = 0;
                    clearInterval(internal);
                }
            };
            const handleVisibilityStateChange = () => {
                if (document.visibilityState === "hidden") {
                    handleAddClue();
                    clearInterval(internal);
                    seconds = 0;
                }
            };

            onMounted(() => {
                internal = setInterval(() => {
                    seconds++;
                }, 1000);
                document.addEventListener(
                    "visibilitychange",
                    handleVisibilityStateChange
                );
                window.addEventListener("pagehide", handleWindowHide, false);
            });
            onUnmounted(() => {
                clearInterval(internal);
                document.removeEventListener(
                    "visibilitychange",
                    handleVisibilityStateChange
                );
                window.removeEventListener("pagehide", handleWindowHide, false);
                handleAddClue();
            });

            searchFileList(state.fileParams).then((data) => {
                if (data[0]) {
                    state.article.image = `${process.env.VUE_APP_WATCH_FILE_API}/${data[0].url}`;
                } else {
                    state.article.image = require("../../assets/images/article-defaultImg-big.png");
                }
            });

            //创建留言
            const saveLeaveMsg = (msg) => {
                state.leavMsg = !state.leavMsg;
                if (msg === "cancel") {
                    state.leaveMsgParams.content = "";
                    state.parentId = ""
                    state.isChild = false
                } else {
                    if (state.leaveMsgParams !== "") {
                      let parentId = ""
                      if (state.isChild){
                        parentId = state.childParentId
                      }else {
                        parentId = state.parentId
                      }
                        let param = {
                            headImgUrl: state.userInfo.headImgUrl,
                            articleId: state.articleId,
                            wetchatUsername: state.userInfo.nickname,
                            content: state.leaveMsgParams.content,
                            parentId:parentId,
                            merchantId:merchantId
                        };
                       createArticleLeavingMsg(param).then(result=>{
                         if (result){
                           console.log("留言成功", result);
                           Toast.success("您的留言已创建");
                           countUserAction({
                             clueId: state.clueId,
                             openId: state.openId,
                             type: 8,
                           });
                           if (state.parentId){
                               getLeavingMsgAllReply(state.parentId)
                           }else {
                             state.current = 1
                             getArticleLeavingMsgByPage()
                           }
                           state.parentId = ""
                           state.isChild = false
                           state.leaveMsgParams.content = "";
                           // loadArticleDetails();
                         }
                       });


                    }
                }
            };
         const handleReplyInfo=(it,child) =>{
           state.leavMsg = true
           state.parentId = it.id
           if (child){
             state.isChild = true
             state.childParentId = child.id
             state.writeLeaveMsg = `@${child.parentWechatUserName}`
           }else {
             state.writeLeaveMsg = `@${it.wetchatUsername}`
           }
           console.log('一级======================',it,child)
         }
            //加载回复
          const getLeavingMsgAllReply = (id) =>{
            findLeavingMsgAllReply({parentId:id}).then(res=>{
              console.log("子回复============",res)
              let reply = JSON.parse(JSON.stringify(res))
              reply.map(it=>{
                it.isChild = true
              })
              state.msgList.map(it=>{
                if (it.id===id){
                  it.reply = reply
                  it.show = false
                  it.hasSubReply = 1
                }
              })
            })
          }
          const closeReply = (id)=>{
            state.msgList.map(it=>{
              if (it.id===id){
                it.reply = []
                it.show = true
              }
            })
            state.showToTop = true
          }
            //加载留言
          const getArticleLeavingMsgByPage = async () =>{
            let p = {
              current: state.current,
              articleId:state.articleId,
              size:5
            }
           let res = await findArticleLeavingMsgByPage(p)
            if (res){
              let morelist = res.records
              morelist.map(it=>{
                it.reply = []
                it.show = true
              })
              if (state.current===1){
                state.msgList = []
                state.msgList.push(...morelist)
              }else {
                state.msgList.push(...morelist)
              }
              if (res.total>5){
                state.showMoreButton = true
              }
              if(res.total===state.msgList.length){
                state.showMoreButton = false
              }
              state.showMore = true
            }
            console.log("留言信息",res)
          }
            //加载文章详情
            const loadArticleDetails = async () => {
                let data = await getArticleById(state.articleId, state.openId);
                state.article.title = data.title;
                state.article.createTime = data.createTime
                    ? data.createTime.slice(0, 10)
                    : "";
                state.article.content = data.content;
                state.article.countOfLikes = data.countOfLikes;
                state.article.countOfCollection = data.countOfCollection;
                state.article.createMerchantId = data.createMerchantId;
                state.article.createMerchantName = data.createMerchantName;
                state.article.userCard = data.userCard;
                state.article.createName = data.createName;
                state.article.loginUserId = data.loginUserId;
                // state.msgList = data.msgList;
                state.isDelete = data.isDeleted;

                state.article.smallCoverPic = data.smallCoverPic;

                state.synopsisStr = data.userCard.selfIntro;
                state.foldSynopsisStr = data.userCard.selfIntro.substring(0, 40);
                state.isLike = data.isLike;
                state.isCollection = data.isCollection;
                if (state.isLike === 0) {
                    state.starShow = true;
                } else {
                    state.starShow = false;
                }
                state.isCollection = data.isCollection;
                if (state.isCollection === 0) {
                    state.collectShow = true;
                } else {
                    state.collectShow = false;
                }

                console.log("微信分享", data);
                state.showSkeleton = false
            };

            //展开折叠
            const handleIsFold = () => {
                state.isFold = !state.isFold;
            };
            return {
                ...toRefs(state),
                handleIsFold,
                handleOpenLeavingMsg,
                handlecombaack,
                handleChoice,
                handleAddClue,
                cardImg,
                handleViewUserCard,
                saveLeaveMsg,
                handleCall,
                handleAddInfo,
                addInfoForm,
                loadOtherHotArticle,
                handletoushu,
                changeOther,
                handleStarOrCollect,
                getLeavingMsgAllReply,
                handleReplyInfo,
                loadMore,
                closeReply
            };
        },
    };
</script>

<style lang="scss">
    .toushu {
        .van-dialog,
        .van-dialog__header,
        .van-dialog__content,
        .van-dialog__message--has-title {
            font-size: 14px;
            font-weight: 700;
            color: #000000;
        }

        .van-button__text {
            font-size: 14px;
            font-weight: 700;
        }
    }

    .wechat_share_container {
        height: 100vh;
        display: flex;
        background-color: #e5e5e5;
        flex-direction: column;
        font-family: PingFang SC;
        // background-color: #ffff;
        .card-box-cls {
            background-color: #fff;
            padding: 5px 16px 16px 16px;
        }

        .card-box {
            // margin: 5px 16px 16px 16px;
            padding: 12px;
            border-radius: 8px;
            text-align: left;
            background-color: #fff;
            box-shadow: 0px 0px 15px rgb(229, 229, 230);

            .synopsis {
                margin-top: 5px;
                font-size: 12px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.84);
            }

            .card-info {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .card-left {
                    display: flex;

                    img {
                        height: 40px;
                        width: 40px;
                        border-radius: 20px;
                    }

                    .creater-info {
                        margin-left: 8px;

                        .title {
                            font-weight: bold;
                            font-size: 14px;
                            color: rgba(0, 0, 0, 0.84);
                            margin-bottom: 3px;
                        }

                        .second {
                            font-weight: normal;
                            font-size: 11px;
                            color: rgba(0, 0, 0, 0.4);
                        }
                    }
                }

                .card-right {
                    .phone {
                        width: 80px;
                        height: 30px;
                        background-color: #289fff;
                        font-size: 12px;
                        color: white;
                        text-align: center;
                        line-height: 30px;
                        border-radius: 5px;
                    }
                }
            }
        }

        //遮罩层
        .consulting {
            font-size: 14px;
            font-weight: bold;
            color: #0000;

            .van-field__label {
                width: 44px;
            }

            .van-field__control {
                font-weight: 500;
                width: 194px;
                border: 1px solid #bfbfbf;
                border-radius: 5px;
            }

            .wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
            }

            .block {
                width: 303px;
                border-radius: 8px;
                background-color: #fff;
                color: black;

                > div {
                    // height: 20px;
                    > div:nth-child(1) {
                        .van-hairline {
                            border-radius: 0 0 0 10px;
                            padding: 0;

                            height: 46px;

                            .van-grid-item__text {
                                color: #999999;
                            }
                        }
                    }

                    > div:nth-child(2) {
                        .van-hairline {
                            border-radius: 0 0 10px 0;
                            padding: 0;

                            .van-grid-item__text {
                                color: #333333;
                            }
                        }

                        [class*="van-hairline"]::after {
                            border-right: none;
                        }
                    }
                }

                [class*="van-hairline"]::after {
                    border-bottom: none;
                }

                .name-card {
                    background-color: #ffff;
                    border-bottom: 1px solid #f1f3f6;
                    padding: 0 16px;

                    p {
                        padding: 0;
                        margin: 0;
                    }

                    .name-info {
                        display: flex;
                        text-align: left;
                        font-size: 14px;
                        color: #333333;
                        padding: 8px 0;
                        // justify-content: space-between;
                        img {
                            height: 40px;
                            width: 40px;
                            border-radius: 20px;
                          object-fit: cover;
                        }

                        .creater-info {
                            margin-left: 10px;

                            > p:nth-child(1) {
                                font-weight: 700;
                            }

                            > p:nth-child(2) {
                                font-size: 12px;
                                color: #666666;
                            }
                        }
                    }

                    .synopsis {
                        text-align: left;
                        font-size: 12px;
                        color: black;
                    }
                }

                .info {
                    padding: 0 10px;

                    .van-cell::after {
                        border: none;
                    }

                    .van-field__label {
                        color: black;
                    }

                    [class*="van-hairline"]::after {
                        border-bottom: none;
                    }
                }
            }
        }

        .header {
            .van-nav-bar__arrow {
                color: #333333;
            }

            .van-nav-bar__title {
                color: #333333;
                font-weight: 700;
            }
        }

        .article-details {
            flex: 1;
            overflow-x: hidden;
            color: rgba(0, 0, 0, 0.56);

            .isDelete {
                font-size: 14px;
                font-weight: 700;
                color: #000000;
                padding-top: 55px;
                padding-bottom: 16px;
            }

            .creater_company {
                font-size: 14px;
                text-align: left;
                border-bottom: 1px solid #f1f3f6;
                background-color: #ffffff;
                padding: 6px 16px;
                font-weight: 400;

                > span:nth-child(1) {
                    margin-right: 16px;
                    // color: #289fff;
                }
            }

            .name-card {
                background-color: #ffff;
                border-bottom: 1px solid #f1f3f6;
                padding: 0 16px;

                p {
                    padding: 0;
                    margin: 0;
                }

                .name-info {
                    display: flex;
                    text-align: left;
                    font-size: 14px;
                    color: #333333;
                    padding: 8px 0;

                    img {
                        height: 40px;
                        width: 40px;
                        border-radius: 20px;
                      object-fit: cover;
                    }

                    .creater-info {
                        margin-left: 10px;

                        > p:nth-child(1) {
                            font-weight: 700;
                        }

                        > p:nth-child(2) {
                            font-size: 12px;
                            color: #666666;
                        }
                    }
                }

                .synopsis {
                    text-align: left;
                    font-size: 12px;
                    color: black;
                }
            }

            .artcile-base-info {
                background-color: #ffffff;
                //  height: 70px;
                //  box-sizing: border-box;
                padding-top: 12px;

                .article_title {
                    padding: 0 16px;
                    text-align: left;
                    font-size: 16px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.84);
                    margin-bottom: 5px;
                    word-wrap: break-word;
                }

                .article_createtime {
                    padding: 10px 16px;
                    text-align: left;
                    font-size: 14px;
                }
            }

            .cover-img {
                padding: 5px 16px;
                height: 160px;
                background-color: #fff;

                .img-box {
                    border-radius: 8px;
                    height: 160px;
                    overflow: hidden;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        // max-height: 100%;
                    }
                }
            }

            .artcle-content {
                // height: 300px;
                width: 100%;
                padding: 0 16px;
                font-size: 14px;
                text-align: left;
                box-sizing: border-box;
                background-color: #ffffff;

                .artcle-content-box {
                    // height: 300px;
                    // background-color: deeppink;
                    line-height: 22px;
                    padding-bottom: 20px;

                    p {
                        margin: 0;
                        padding: 0;
                    }

                    border-bottom: 1px solid #f1f3f6;
                }
            }

            .article_statement {
                color: #bfbfbf;
                background-color: #ffff;
                font-size: 12px;
                text-align: left;
                line-height: 22px;
                padding: 5px 16px;
                font-weight: 400;
                border-bottom: 1px solid #f1f3f6;
            }

            .star-collection {
                display: flex;
                justify-content: space-between;
                padding: 0 16px;
                background-color: #ffffff;

                .star-and-collection {
                    height: 34px;
                    font-size: 14px;
                    color: #333333;

                    display: flex;
                    // border-bottom: 1px solid #F1F3F6;
                    // border-bottom: 1px solid #cccccc;
                    > div {
                        display: flex;
                        align-items: center;
                        margin-right: 10px;
                    }

                    img {
                        margin-right: 5px;
                    }
                }

                .leave-mesage-complaint {
                    display: flex;
                    font-size: 12px;
                    font-weight: 700;
                    align-items: center;

                    > div:nth-child(1) {
                        margin-right: 10px;
                        height: 30px;
                        color: #ffffff;
                        width: 72px;
                        line-height: 30.5px;
                        border-radius: 5px;
                        background-color: #bfbfbf;
                    }

                    > div:nth-child(2) {
                        color: black;
                    }
                }
            }

            .star-leaving-box {
                display: flex;
                justify-content: space-between;
                background-color: #ffffff;
                padding: 0 16px;

                .star-collection {
                    height: 34px;
                    font-size: 14px;
                    color: #333333;
                    padding: 0;
                    background-color: #ffffff;
                    display: flex;
                    // width: 200px;
                    // line-height: 34px;
                    justify-content: space-between;
                    // padding: 0 50px;
                    // border-bottom: 1px solid #cccccc;
                    > div {
                        display: flex;
                        align-items: center;
                        margin-right: 16px;

                        img {
                            margin-right: 5px;
                        }
                    }
                }

                .leaving-botton-ts {
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    color: #000000;
                    font-weight: 700;

                    > button {
                        width: 72px;
                        height: 30px;
                        border: none;
                        color: white;
                        border-radius: 5px;
                        margin-right: 29px;
                        background-color: #289fff;
                    }

                    .button-bc {
                        background-color: #bfbfbf;
                    }
                }
            }

            .leave-message-box {
                //  border-bottom: .5px solid #F1F3F6;
                border-top: 0.5px solid #f1f3f6;
                background-color: #ffff;
                padding: 12px 0;

                .user-info {
                    background-color: #ffff;
                    border-bottom: none;
                    padding: 0 16px;
                  .img-content{
                    display: flex;
                    img{
                      height: 40px;
                      width: 40px;
                      border-radius: 20px;
                      object-fit: cover;
                    }
                    .name-content{
                      text-align: left;
                      margin-left: 10px;
                      padding-top: 5px;
                      .name{
                        font-size: 14px;
                        color: #333333;
                        font-weight: 600;
                      }
                      .content{
                        font-size: 14px;
                        color: #666666;
                        padding: 6px 0;
                      }
                    }
                  }

                }
              .margin-cls{
                margin-left: 45px;
              }
              .add-margin{
                margin-left: 60px;
              }
              .leave-message-time {
                right: -180px;
                font-size: 12px;
                text-align: left;
                margin-left: 27px;
                display: flex;
                align-items: center;
                padding-bottom: 5px;
                .icon-cls{
                  font-size: 16px;
                  color: black;
                  margin-right: 20px;
                }
                .time{

                }
                .reply{
                  margin-left: 8px;
                  color:#289FFF;
                }
              }
              .leave-message-time-child {
                right: -180px;
                font-size: 12px;
                text-align: left;
                margin-left: 65px;
                display: flex;
                align-items: center;
                .icon-cls{
                  font-size: 16px;
                  color: black;
                  margin-right: 20px;
                }
                .time{

                }
                .reply{
                  margin-left: 8px;
                  color:#289FFF;
                }
              }
              .leave-message-time-child {
                right: -180px;
                font-size: 12px;
                text-align: left;
                display: flex;
                align-items: center;
                .icon-cls{
                  font-size: 16px;
                  color: black;
                  margin-right: 20px;
                }
                .time{

                }
                .reply{
                  margin-left: 8px;
                  color:#289FFF;
                }
              }

                //.name-info {
                //    width: 100%;
                //    display: flex;
                //    justify-content: space-between;
                //    padding-bottom: 0;
                //    .creater-info {
                //        // float: right;
                //        font-weight: bold;
                //        font-size: 14px;
                //        // color: #333333;
                //        // height: 40px;
                //        // line-height: 40px;
                //        width: 215px;
                //    }
                //
                //    .head-name {
                //        display: flex;
                //        align-items: center;
                //        flex: 1;
                //    }
                //}



                .synopsis {
                    font-size: 14px;
                    color: #666666;
                    line-height: 22px;
                }
            }

            .create-leave-message {
                position: fixed;
                z-index: 999;
                bottom: 50px;
                border-top: 0.5px solid #f1f3f6;
                width: 100%;

                .leave-message-time {
                    position: relative;
                    right: -200px;
                }

                .synopsis {
                    .van-cell::after {
                        border: none;
                    }

                    .van-cell {
                        // padding-right: 0;
                        // padding-left: 40px;
                        padding: 0 0 0 40px;
                    }

                    .van-field__control {
                        border: 0.25px solid #bfbfbf;
                        padding: 0;
                    }

                    .cancel-save {
                        height: 53px;
                        line-height: 53px;
                        display: flex;
                        align-items: center;
                        flex-direction: row-reverse;
                        // border: 1px solid #ffffff;
                        .van-button {
                            height: 28px;
                            width: 72px;
                            border-radius: 5px;
                            font-size: 12px;
                            margin-left: 15px;
                        }
                    }
                }
            }

            .hot-article {
                font-size: 14px;
                color: #000000;
                font-weight: bold;
                margin-bottom: 30px;

                > p {
                    margin: 0;
                    padding-top: 8px;
                    padding-bottom: 10px;
                    text-align: left;
                    padding-left: 16px;
                    background-color: #fff;
                }

                .hot-title {
                    background-color: #e5e5e5;
                }

                .changeOther {
                    font-size: 14px;
                    color: #cccccc;
                    background-color: #fff;
                    padding: 20px 0;
                }

                .hot-article-box {
                    display: flex;
                    align-items: center;
                    background-color: #ffff;
                    padding: 5px 16px;
                    font-weight: 600;

                    > img {
                        height: 40px;
                        width: 40px;
                        border-radius: 20px;
                    }

                    > span {
                        text-align: left;
                        margin-left: 8px;
                    }
                }
            }
        }

        .wechat_footer {
            position: relative;
            height: 50px;

            .add_article {
                height: 36px;
                width: 52px;
                color: #fff;
                line-height: 36px;
                border-radius: 8px;
                font-size: 28px;
                position: fixed;
                z-index: 2;
                left: 50%;
                bottom: 7px;
                transform: translateX(-50%);
                background-color: #ff6e01;
            }

            .van-tabbar-item__text {
                display: flex;
                flex-direction: column;
                align-items: center;

                .zixun {
                    display: block;
                    margin-bottom: 3px;
                    font-size: 22px;
                }
            }
        }
    }
</style>
